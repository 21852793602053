
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'About',
  setup () {
    useMeta({
      title: 'About'
    })
  }
})
